import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionWhatWeDo from "../components/sectionWhatWeDo.js"

const PageWhatWeDo = (props) => {
  return (
    <Layout>
      <SEO title="3 Leaps LLC - What We Do"/>
      <SectionWhatWeDo props={props}/>
    </Layout>
  )
}

PageWhatWeDo.propTypes = {
  backgroundColor: PropTypes.string
}
PageWhatWeDo.defaultProps = {
  backgroundColor: "neutral-1"
}

export default PageWhatWeDo
