import React from "react"
import { BusinessService, Technology } from "grommet-icons"
/*
 *  DET FIXME - move this to a back-end database instead of storing directly here
 */
const testList1 = ["RFP Response Management", "Program Management", "Build/Buy Analysis", "Technology Health Assessment"]
const testList2 = ["Data Acquisition and Wrangling", "Performance Tuning"]

// each tag MUST be unique!
export const whatWeDoAreas = [
  {
    tag: "business",
    label: "Business",
    tabTitle: "For Business Leaders",
    tabIndex: 0,
    tabIcon: BusinessService,
    tabColor: "brand",
    tabFocusColor: "red"
  },
  {
    tag: "technology",
    label: "Technology",
    tabTitle: "For Technology Leaders",
    tabIndex: 1,
    tabIcon: Technology,
    tabColor: "neutral-1",
    tabFocusColor: "yellow"
  }
]

export const whatWeDoData = [
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "business"),
    tag: "RFP Management",
    intro: `* Planning to upgrade or add an enterprise optimization system in pricing, supply chain or related areas?  
* Retooling key back office components?`,
    detail: `We have built, managed and been selected scores of times for complex, 
    cross-functional fulfillment and optimization solutions in large enterprises all over the world.  
    &nbsp;  
    Having been seated on both sides of the table many times, we know the key questions to ask, 
    the important success criteria, the "gotchas", and the project structures 
    that can make or break a project on tight timelines.  
    Our key capabilities include:  

+ gathering project needs and building evaluation criteria
+ assisting with business cases and the approval process
+ coordinating with your IT leadership to incorporate applicable regulatory and governance elements
+ managing or guiding the RFP process from blueprint approval to vendor selection  

We'll also use our broad network also to gain additional advice (where needed and with your consent) 
    to give you impartial advice with a work product you can use for future activities.`,
  },
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "business"),
    tag: "Virtual CIO / CTO Assistance",
    intro: `* Have an initiative that requires experienced leadership?
* Could you benefit from some additional product and solution management expertise? 
* Have strategic projects that are missing key resources or facing execution problems?
* Need an independent perspective on some core decisions or challenges you face?`,
    detail: `We've delivered hundreds of projects and implementations, many with 7 or 8 figure budgets. 
    Our team can help you with virtual CIO / CTO services to extend your productivity 
    and to help ensure your organization keeps its commitments.  
    &nbsp;  
    Our team can help in a variety of ways, from mentorship to direct leadership and from key project management 
    to organization-level supervision.  We've led teams from dozens to hundreds in size, build complex analytics and
    decision optimization solutions, and re-tooled enterprise-class infrastructure to meet changing demands. 
    Whether taking the reins for a key project, helping to align vendors and team around large initiatives, 
    or helping you transform your organization, we can help you achieve your strategic goals.  
    &nbsp;  
    We are full stack in data management, data science, analytics, cloud computing, IT, and software development, 
    operating from product and business case management through the entire delivery process as needed. 
    From the business case presentations and financial models to RFP management 
    and from enterprise architecture to acceptance testing, we can help you keep your commitments.`,
  },
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "business"),
    tag: "Solution Architecture",
    intro: `* Know you need to _change things up_ but not sure where to start?  
* Have a sense of what is wrong but not sure how to prioritize?`,
    detail: `Our team has built and implemented back-office, fulfillment, analytics and optimization solutions 
    across a number of different industries. We'll work with you and your consulting partners to understand 
    the key elements of your strategy and map that into a thorough review of your current landscape.  
    &nbsp;  
    We'll review technology, time and talent to assess not only what you can do with your current environment, 
    but also how other priorities and projects may impact a new initiative. 
    Working with your team, we can then propose several options for improvements, 
    extensions and replacements that align with your goals and priorities. 
    If you decide to take action, we can help you with program management and delivery too!`,
  },
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "business"),
    tag: "Build / Buy Assessment",
    intro: `
Planning an initiative and deciding whether to go outside for the solution?`,
    detail: `Our team understands how the business priorities and current project portfolio can affect the success of a new key initiative.
    Using our experience delivering literally scores of large-scale enterprise solutions in the real world,
    we can build a portfolio of options, assess risk, recommend vendors, and work with your technologists to refine plans.  
    &nbsp;  
    If you decide to buy, we can help manage an RFP process for you as well that ensures your stakeholders and interests are best represented.  
    &nbsp;  
    If you decide to build, we can provide a range of services from project management to enterprise architecture and from data acquisition to data science.
    `,
  },
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "business"),
    tag: "Integration Services",
    intro: `* Is a key project blocked due to integration or data management issues?  
* Is your technical staff unable to deliver because of other priorities and commitments?  
* Do you have an initiative that is not moving along as planned because of data issues?  
* Have a legacy system that isn't providing what you need?  
* Are business partners demanding APIs or data access that your team can't deliver due to other factors?`,
    detail: `We have 
deep expertise in  

* data wrangling (ETL, data loading, cleansing)  
* data streaming and structured exports
* API & middleware development and integration
* filtering, structuring, and modeling  


For your project, we can develop detailed specifications and test plans, act as an advisor to your project team, 
or deliver running interfaces to you in a bespoke fashion.  
&nbsp;  
If you need APIs or middleware to enable business partners or to bridge a gap on a legacy system, 
we can get your interface built in a reliable manner. 
Depending on the needs and landscape, we can leverage industry tools and middleware solutions, 
or we can build the interface directly in code. 
This kind of work can usually proceed without blocking other project activities.   
&nbsp;  
We can apply this expertise in business process automation work as well. 
In many cases, one can speed or simplify day-to-day use of complex back-office and optimization systems 
through process automation.  We discuss this in more detail in the Business Process Automation topic.`,
  },
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "business"),
    tag: "Business Process Automation",
    intro: `* Do you feel as though managing your operational, analysis and optimization systems requires too much manual work?  
* Is your staff having trouble completing key process steps or making decisions during peak periods?
* Are your colleagues the ability to review insights and make decisions while mobile?
* Have you or your strategic consulting partners identified potential profit leakage areas at key decision points?
    `,
    detail: `We can help identify improvements at different levels of scale and complexity, ranging from simple workflow automation 
    to system re-architecture.  Most enterprise back-office, analytics and optimization tools have APIs or machine interfaces to facilitate tighter integration.
    These interfaces are often overlooked or de-scoped in implementation projects as teams deal with other problems.  
    We can help with what we call _decision scaling_, leveraging automation, interfaces and the capabilities of your existing systems.
    We will work with your solution vendors and consulting partners to ensure the highest value areas are addressed.  
  `},
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "business"),
    tag: "Decision Optimization Strategy",
    intro: `* Are you just starting to explore how data science and machine learning systems can accelerate your business?  
* Do you have a project that is under stress or not producing the anticipated results?  
* Are you exploring how to integrate and improve the results from your existing expert systems?
* Have you looked at the potential to harness your client and customer interaction data to spot problems, trends and opportunities?
    `,
    detail: `Our firm's resume includes significant innovations in optimization and analytical systems. 
    We have built, integrated, and implemented a variety of solutions using industry-leading techniques and technologies.
    We can help you develop a comprehensive strategy for data science and decision optimization, working with your 
    firm's strategic consulting partners and in-house team to align with business goals.  We can identify quick wins, 
    map potential benefits, and help get you started.
    &nbsp;  
    
Our firm can help in other areas further downstream as well. 
Read about our Data Wrangling, Business Process Automation, and Data Science capabilities to learn more.`
  },
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "technology"),
    tag: "Data Wrangling",
    intro: `* Need more datasets to support your stakeholders' analytics?
* Need some expertise in data cleansing and warehousing?  
* Ready to enable real-time data streaming?
    `,
    detail: `Our team has _decades_ of experience managing hot data at *terabyte-scale*! 
    We can you help enable new insights and refine existing models to speed decision making.  
    We can help you deliver value quickly, whether you need to improve cleaning and filtering 
    on your existing data sets, enable real-time streaming, or enrich the portfolio with new data.  
    &nbsp;  
    Data wrangling needs often arise from data science projects. 
    Read more about our how we can help in the Data Science section.
    `
  },
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "technology"),
    tag: "Performance Tuning",
    intro: `* Are you facing SLA issues during peak periods?
* Are users frustrated with system availability or UI performance?`,
    detail: `We've architected, built and managed SaaS solutions with terabyte-scale hot data and complex data modeling 
    and sophisticated data science on tight SLAs. We've done this using all the major cloud environments and using private infrastructure as well. 
    &nbsp;  
    Our team has deep expertise and experience across the spectrum with large scale systems, including:  
    
 * networking
 * storage and infrastructure deployment and monitoring
 * database design, management and tuning
 * ETL work (what we call "Data Wrangling")
 * system performance tuning.  
 &nbsp;  
We'll review current performance against expectations, 
identify quick-wins and build a detailed list of initiatives that you can use as a work plan. 
Because our team works up and down the stack, we can direct initiatives, act as part of your team, 
or manage the resolution process end to end.  
&nbsp;  
We are practicing technologists who can be directly involved in the work for the most critical needs.`,
  },
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "technology"),
    tag: "System Health Check",
    intro: `* Are you looking for expertise and indepedent review on your systems, teams, and environment?
* Need some help identifying or prioritizing the most important actions for your limited resources?
* Worried about the impact of new compliance or regulatory requirements on your systems?`,
    detail: `We've led teams of dozens to hundreds of people across numerous disciplines in IT and technology, 
    for "green field", "re-tool", and "re-start" scenarios. 
    We've managed live solutions through PCI, SOC, GPDR, and other regulatory initiatives too.  
 &nbsp;  
We'll thoroughly research and map your environment, review current projects, commitments, 
and priorities. If needed, we can benchmark your team and tools against the industry while considering 
your current and future needs.  
&nbsp;  
We'll scope and prioritize recommendations based on your requirements and provide a detailed report for you and your leadership colleagues.`,
  },
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "technology"),
    tag: "Virtual CIO / CTO Assistance",
    intro: `* Do you have more projects than management bandwidth?
* Need help making sure teams and vendors have direction and are on track?
* Having trouble getting budget and financial models in order?
* Could you benefit from process monitoring or management to increase productivity and alignment?`,
    detail: `We've delivered hundreds of projects and implementations, many with 7 or 8 figure budgets. 
    Our team can help you with virtual CIO / CTO services to extend your productivity 
    and to help ensure your organization keeps its commitments.  
    &nbsp;  
    Whether taking the reins for a key project, acting as a guide or reviewer, or owning the full delivery, 
    we can help your team increase delivery by providing key technical and management expertise.  
    &nbsp;  
    We are full stack in data management, data science, analytics, IT, and software development. 
    From the business case presentations and financial models to the design and implementation, 
    we can help you keep your commitments.`,
  },
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "technology"),
    tag: "Analytics and BI Up-scaling",
    intro: `* Are you having trouble scaling your data model?
* Are business users missing critical analyses with inconsistent, incomplete, or late results?
* Are your models and proprietary systems lacking key data in the right form?
* Do you want to provide more mobile-ready insights? 
* Are you caught in an upgrade trap or have a key system running end-of-life?`,
    detail: `We know BI and data warehousing, from operational and financial reporting 
    to unstructured, real-time analytics. We will work with your vendors and solutions 
    to overcome key issues and improve results.  
    &nbsp;  
    We've worked through many updates, upgrades, minor replacements and full-stack replacements. 
    We'll use our experience to help you recommend and implement the most valuable changes for your needs and budget.  
    &nbsp;  
    Analytics issues often arise in the data layer due to interface, loading or cleansing issues.  
    Review the Data Wrangling topic for more information on our capabilities here.`,
  },
  {
    areaTag: whatWeDoAreas.find(item => item.tag === "technology"),
    tag: "Data Science and Insight Generation",
    intro: `* Are you leveraging your customer and client interaction data to spot problems and changing trends?
* Have you blocked or held back on unique insights for lack of confidence in the data?
* Are you coordinating the interactions of your expert modeling systems with a "single version of the truth"?
* Are your front-line staff armed with the most up to date insights from your processes and systems?
`,
    detail: `The major cloud providers all have powerful data science tools today that can greatly accelerate projects in this area. 
    Our team has experience in this area ranging from small-scale classification problems to complex multi-system integration. 
    For problems requiring specialized systems and expertise, we can help identify potential solutions, 
    recommend reputable vendors, and manage or assist with integration.  
    &nbsp;  
    These types of projects often involve the need for RFPs. 
    We describe more about our capabilities and experience in this area under the Business Leaders tab.`,
  },
]