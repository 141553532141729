
/*
 *  DET FIXME - move this to a backend such as strapi or firebase
 *  DET FIXME - as content expands make the our thoughts page use hot topic at top and sort by time
 *  The story content is stored in markdown-pages/stories - the tag in markdown
 *  corresponds to tag here - make sure they are in sync
 *  Also make sure the topic index values are properly in order - making any undefined or null will eliminate / disable
 */
export const storiesMetadata = [
  {
    tagline: "Innovation Needs An Overhaul",
    tag: "innovation-overhaul",
    actionCall: "Let's update our trusty Crawl, Walk, Run methodology to deliver results faster",
    imageIdentifier: "CARD-INNOVATION-OVERHAUL-2",
    imageCatalog: "STANDARD",
    textColor: "light-1",
    defaultStoryIndex: 0,
    active: true,
  },
  {
    tagline: "Volatility Looms Large",
    tag: "volatility-looms-large",
    actionCall: 'Supply and demand models are stressed as perhaps never before',
    imageIdentifier: "CARD-VOLATILITY",
    imageCatalog: "STANDARD",
    textColor: "light-4",
    defaultStoryIndex: 1,
    active: true,
  },
  {
    tagline: "History Is Not What It Used To Be",
    tag: "history-not-same",
    actionCall: 'COVID-19 has altered the norm, and may do so yet again.  What worked yesterday may be largely irrelevant today',
    imageIdentifier: "CARD-HISTORY-NOT-SAME",
    imageCatalog: "STANDARD",
    taglineTextColor: "light-2",
    textColor: "accent-4",
    defaultStoryIndex: 2,
    active: true,
  },
  {
    tagline: "Finding Opportunity In Uncertainty",
    tag: "opportunity-in-uncertainty",
    actionCall: 'When significant events disrupt the competitive landscape, fortune favors those who see and adapt the fastest',
    imageIdentifier: "CARD-OPPORTUNITY-JUMPING",
    imageCatalog: "STANDARD",
    actionTextColor: "accent-4",
    taglineTextColor: "light-2",
    textColor: "black",
    defaultStoryIndex: 3,
    active: false,
  },
  {
    tagline: "Trust Is Built on Small Promises Made and Kept",
    tag: "history-not-same",
    actionCall: 'Always reliable supply may be the new differentiator',
    imageIdentifier: "CARD-TRUST-MADE-KEPT",
    imageCatalog: "STANDARD",
    textColor: "light-2",
    defaultStoryIndex: 4,
    active: false,
  },
];
