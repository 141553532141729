import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
import queryString from "query-string"

import { Box, Button, Heading, Markdown, Text, Accordion, AccordionPanel, Tab, Tabs, Paragraph } from "grommet"
import { BusinessService, Technology } from "grommet-icons"
import { graphql, useStaticQuery } from "gatsby"
import ImageSharpImage from "../components/imageHandling/imageSharpImage"
import BoxDivider from "./boxDivider"
import ResponsiveGrid from "../components/responsiveGrid"

import { whatWeDoAreas, whatWeDoData } from "../components/data/whatWeDoData"
import { storiesMetadata } from "./data/storiesMetadata"

const siteDataQuery = graphql`query 
  {
    brandMetadata: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/company-info/"}}, sort: {fields: fileAbsolutePath, order: ASC}) {
      edges {
        node {
          id
          rawMarkdownBody
          fileAbsolutePath
          excerpt
          html
          frontmatter {
            title
            tag
          }
        }
      }
    },
    whoWeAre: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "\/who-we-are\/"}}, sort: {fields: fileAbsolutePath, order: ASC}) {
      edges {
        node {
          id
          rawMarkdownBody
          fileAbsolutePath
          excerpt
          html
          frontmatter {
            title
            tag
          }
        }
      }
    },
}`

const SectionWhatWeDo = (sectionProps) => {
  const useMountEffect = (fun) => useEffect(fun, [])

  const siteData = useStaticQuery(siteDataQuery)
  const brandMetadataMarkdownTaglineMarkdown =
    siteData.brandMetadata.edges.filter(md => md.node.frontmatter.tag === "how-we-help")[0].node.rawMarkdownBody

//  const [activeAccordionIndices, setActiveAccordionIndices] = useState([{0: null}, {1: null}])
  /*
   *  This is used to determine which content is visible based on selection from initial url and from button/tab clicks
   *  It is possible to navigate directly to an area using these url props
   *  section (valid values are in from whatWeDoAreas - the tag property)
   */
  const [selectedSection, setSelectedSection] = useState(sectionProps.defaultSectionTag)
  // this will be initialized in the tab setup
  const [activeBackgroundColor, setActiveBackgroundColor] = useState(null)

  /*
   *  Build an array of elements to track the index of an accordion element inside each tab
   *  We will use a setter to add as we build the tabs
   */
//  const [accordionIndices, setAccordionIndices] = useState([])

  /*
   *  We will derive the tab index from the whatWeDoAreas data, based on section
   */
  const [activeTabIndex, setActiveTabIndex] = useState(whatWeDoAreas.find(elem => elem.tag === sectionProps.defaultSectionTag).tabIndex)

//  console.log("what we do area = " + JSON.stringify(whatWeDoAreas, null, 2))
//  console.log("what we do data = " + JSON.stringify(whatWeDoData, null, 2))

//  console.log(JSON.stringify(whatWeDoData.filter(item => item.areaTag === whatWeDoAreas.business)))
//  console.log('Some areas = ' + Object.keys(whatWeDoAreas).forEach(e => `key=${e} value=${whatWeDoAreas[e]}`))

  // use as a one shot to run before the page loads
  const [initialEntry, setInitialEntry] = useState(true)

  /*
   * We can trust getting the location information from props so long as the function has it
   * ie no need to wait for page loaded
   */
  useEffect(() => {
    if (initialEntry) {
//      console.log("Initializing props on load = " + JSON.stringify(sectionProps, null, 2));

      // now set the section based on search
//      console.log('The search string = ' + sectionProps.props.location.search)
      /*
       *  Because props is buried in our props (should change how we define to avoid), we need to remove leading ?
       *  as the search param may be stringified (ie ?topic would be name instead of topic)
       */
      const searchUrl = queryString.parse(sectionProps.props.location.search.replace(/^(\?)/, ""))

//    console.log("The tags = " + whatWeDoAreaTags)
      const theNewSectionValue =
        (searchUrl.section !== undefined && whatWeDoAreas.find(elem => elem.tag === searchUrl.section)) ?
          searchUrl.section :
          sectionProps.defaultSectionTag;

      // remember the value will not update in this routine - use the value we just calculated to set derived tab index
      setSelectedSection(theNewSectionValue)
      console.log("The initial section = " + JSON.stringify(selectedSection))
      whatWeDoAreas.filter(elem => elem.tag === theNewSectionValue).map(data => {
        setActiveTabIndex(data.tabIndex);
        setActiveBackgroundColor(data.tabColor)
        console.log('Setting new tab index = ' + data.tabIndex + ' and color = ' + data.tabColor)
        // update the array using tab index as a key - one accordion per tab
//        setActiveAccordionIndices(prevState => ({
//          activeAccordionIndices: {
//            ...prevState.activeAccordionIndices,
//            [prevState.activeAccordionIndices[data.tabIndex]]: null,
//          }
//        }));
      })


      // don't run this again
      setInitialEntry(false)
//      setInitialEntry(false)
    }
//    console.log("Hello initial entry, state = " + initialEntry)
  }, [initialEntry])

  const pageLoaded = () => {
//    console.log("The window props = " + JSON.stringify(window.location, null, 2))
//    const searchUrl = queryString.parse(window.location.search)
//    console.log("The window props = " + JSON.stringify(searchUrl, null, 2))
    // if we don't have a prop, we will use default of a page prop called defaultSection
  }

  const onTabSelected = (value) => {
    setActiveTabIndex(value)
    // and update the section based on this
    const theArea = whatWeDoAreas.find(elem => elem.tabIndex === value)
    const newSection=theArea.tag
    console.log('Updating section to ' + newSection)
    setSelectedSection(newSection)
    // remember to use the current value because the setters haven't run yet - now change background
    console.log('Updating color to ' + theArea.tabColor)
    setActiveBackgroundColor(theArea.tabColor)

    // update the array using tab index as a key - one accordion per tab
//    setActiveAccordionIndices(prevState => ({
//      activeAccordionIndices: {
//        ...prevState.activeAccordionIndices,
//        [prevState.activeAccordionIndices[activeTabIndex]]: null,
//      }
//    }));

  }

  // DET FIXME - make them look like buttons
  const RichTabTitle = ({ icon, label }) => (
    <Box direction="row" align="center" gap="xsmall" margin="xsmall">
      {icon}
      <Text size="large">
        <strong>{label}</strong>
      </Text>
    </Box>
  );
  const RichTabTitle2 = ({ icon, label }) => (
    <Box direction="row" align="center" gap="xsmall" margin="xsmall">
      <Button icon={icon} label={label}/>
    </Box>
  );

const accordionMember = (whatWeDoDataEntry) => {
    /*
     *  The width of the surrounding tab set will not expand unless these members do - set the box parameters
     */
//    console.log('Filling in member ' + JSON.stringify(whatWeDoDataEntry, null, 2))
    return (
      <Box fill>
        <ResponsiveGrid
          // align is for cross axis
          align={"start"}
          fill={true}
          justify={"center"}
          // this controls how elements stay together in row axis - use start
          justifyContent={"start"}
          alignContent={"start"}
          //align self moves the whole thing in row axis
          alignSelf={"end"}
          rows={["fit", "full"]}
          columns={["fit", "flex"]}
          areas={{
            small: [
              { name: "intro", start: [0, 0], end: [1, 0] },
              { name: 'detail', start: [0, 1], end: [1, 1] },
            ],
            medium: [
              { name: "intro", start: [0, 0], end: [0, 1] },
              { name: 'detail', start: [1, 0], end: [1, 1] },
            ],
            large: [
              { name: "intro", start: [0, 0], end: [0, 1] },
              { name: 'detail', start: [1, 0], end: [1, 1] },
            ],
            full: [
              { name: "intro", start: [0, 0], end: [0, 1] },
              { name: 'detail', start: [1, 0], end: [1, 1] },
            ],
          }}
        >
          <Box
            gridArea={"intro"}
            fill
            pad={"small"}
            alignContent={"center"}
            background={{color: whatWeDoDataEntry.tabFocusColor}}
          >
            <Markdown
              components={{ p: (props) => <Paragraph {...props} fill/> }}
            >
              {whatWeDoDataEntry.intro}
            </Markdown>
          </Box>
          <Box
            fill
            pad={"small"}
            gridArea={"detail"}
          >
            <Markdown
              components={{ p: (props) => <Paragraph {...props} fill/> }}
            >
              {whatWeDoDataEntry.detail}
            </Markdown>
          </Box>
        </ResponsiveGrid>
      </Box>
    )
  }

  /*
   *  Each section (whatWeDoAreas) has one or more content elements that will go.  We populate based on the
   *  business section
   */
  const fillPanels = (theWhatWeDoAreaTag) => {
    let accordionArray = [];
    console.log('Building accordion content for section ' + theWhatWeDoAreaTag.tag)
//    console.log('The filter set = ' + JSON.stringify(whatWeDoData.filter(data => data.areaTag.tag === "technology")))
    for (const entry of whatWeDoData.filter(data => data.areaTag.tag === theWhatWeDoAreaTag)) {
      console.log('Adding panel = ' + entry.tag)
      accordionArray.push(
        <AccordionPanel label={entry.tag}>
          {accordionMember(entry)}
        </AccordionPanel>)
    }
    return (accordionArray)
  }

  const onActiveAccordionPanelChange = (value) => {
    console.log('Setting new accordion index to ' + value[0])
    // update the array using tab index as a key - one accordion per tab
//    setActiveAccordionIndices(prevState => ({
//      activeAccordionIndices: {
//        ...prevState.activeAccordionIndices,
//        [prevState.activeAccordionIndices[activeTabIndex]]: value[0],
//      }
//    }));
//    setActiveAccordionIndex(value[0])
  }
  /*
   *  The tabs are data driven, as nodes in the whatWeDoAreas setup.  We fill with accordion panels
   *  from the content that matches section
   */
  const fillTabs = () => {
    let tabArray = [];
    for (const entry of whatWeDoAreas) {
      // set the background color for this section
      // set up an accordion index for each tab - we will update them as the accordion gets set.
//      setAccordionIndices(indices => [...accordionIndices, ({section: entry.tag, accordionIndex: null})])

      // set up an accordion index for each tab - we will update them as the accordion gets set.
      /*
                   activeIndex={accordionIndices.find(e => e.section === entry.tag)}
            onActive={onAccordionPanelSelected}

       */
      console.log('Adding tab for section ' + entry.tag)
      tabArray.push(
          <Tab
            title={<RichTabTitle icon={<entry.tabIcon/>} label={entry.tabTitle} />}
          >
            <Box
              fill
              background={{"color": `${activeBackgroundColor}`}}
              border={{color: "black"}}
            >
              <Accordion
                a11yTitle={"How We Can Help by Topic 2 - click a row for more information"}
                animate={true}
//                active={activeAccordionIndices[activeTabIndex]}
//                onActive={onActiveAccordionPanelChange}
                multiple={false}
                children={fillPanels(entry.tag)}
              />
            </Box>
          </Tab>
      )
    }
    return (tabArray)
  }

  useMountEffect(pageLoaded)


  /*
   *  CSS notes for parapgraphs - have found these props to not have an effect, other than the size one
   *  Probably still don't understand how to get them to apply - see the inside of components for Markdown
   *  , "props": {"overflow": "hidden", "white-space": "nowrap", "text-overflow": "..."}
   */
  return (
    <Box
      fill={true}
      direction={"column"}
      background={{ "color": sectionProps.backgroundColor }}
    >
      <Box
        fill={false}
        height={{ "max": "large" }}
        width={{ "max": "xxlarge" }}
        alignSelf={"center"}
        pad={"medium"}
      >
        <Heading level={2} alignSelf={"center"} textAlign={"center"}>
          <Markdown
            components={{ p: (props) => <Paragraph {...props} fill/> }}
          >
            {brandMetadataMarkdownTaglineMarkdown}
          </Markdown>
        </Heading>
        <ImageSharpImage
          imageIdentifier={"CONCEPT-DECISION-MAKING-GROUP"}
        />
      </Box>
      <BoxDivider/>
      <Box
        direction={"column"}
        alignContent={"stretch"}
        // fill not used yet
      >
        <Tabs
          activeIndex={activeTabIndex}
          justify={"center"}
          children={fillTabs()}
          onActive={onTabSelected}
        />
      </Box>
    </Box>
  )
}

SectionWhatWeDo.propTypes = {
  backgroundColor: PropTypes.string,
  activePanelBackgroundColor: PropTypes.string,
  activePanelTextColor: PropTypes.string,
  defaultSectionTag: PropTypes.string
}

SectionWhatWeDo.defaultProps = {
  backgroundColor: "light-4",
  activePanelBackgroundColor: "red",
  activePanelTextColor: "yellow",
  defaultSectionTag: whatWeDoAreas.find(e => e.tag === "business").tag
}

export default SectionWhatWeDo
